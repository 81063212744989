<template>
    <st-page
        :title="$t('INTERNAL_DOCUMENTS.LIST.TITLE')"
    >
        <template #toolbar>
            <InternalDocumentsListToolbar @add="add"/>
        </template>
        <div v-if="initLoading">
            <InternalDocumentsListFilter ref="InternalDocumentsListFilter" >
                <InternalDocumentsListTable>
                </InternalDocumentsListTable>
            </InternalDocumentsListFilter>
        </div>
        <SelectOrganisationModal
            ref="selectOrganisationModal"
            @organisationSelected="organisationSelected"
        ></SelectOrganisationModal>
    </st-page>
</template>

<script>
import InternalDocumentsListFilter from '@/modules/internal-documents/components/InternalDocumentsListFilter';
import InternalDocumentsListTable from '@/modules/internal-documents/components/InternalDocumentsListTable';
import InternalDocumentsListToolbar from '@/modules/internal-documents/components/InternalDocumentsListToolbar';
import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';
import Roles from '@/security/roles';
import { mapGetters } from "vuex";

export default {
    name: "InternalDocumentsList",
    components: {
        InternalDocumentsListFilter,
        InternalDocumentsListTable,
        InternalDocumentsListToolbar,
        SelectOrganisationModal
    },
    data() {
        return {
            initLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            organisation: 'administrator/institution',
            currentUser: 'auth/currentUser',
        }),
        isSuperAdmin() {
            return this.currentUser.user_type_name === Roles.values['super_admin']
        }
    },
    methods: {
        async organisationSelected() {
            this.$refs.selectOrganisationModal.hide();
            this.initLoading = true;
        },
        add() {
            this.$router.push({
                name: 'internalDocumentNew'
            });
        }
    },
    mounted() {
       if (this.isSuperAdmin) {
            this.initLoading = false;
            this.$refs.selectOrganisationModal.show();
        } else {
            this.initLoading = true;
        }
    }
};
</script>
