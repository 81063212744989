<template>
    <div>
        <st-modal
            id="st-modal-select-organisation"
            hide-header-delimiter
            hide-footer-delimiter
            hideable
            size="md"
            :title="$t('INSTITUTION.ORG_SELECTION_FORM.HEADER')"
            customClass="form-modal"
            ref="modal"
        >
            <template #body>
                <select-organisation-form
                    ref="select-organisation-form"
                    :organisation="organisation"
                    @cancelForm="hide"
                    @organisationSelected="organisationSelected"
                >
                </select-organisation-form>
            </template>
            <template #footer>
                <div class="d-flex justify-content-end w-100">
                    <st-button
                        size="large"
                        variant="primary"
                        :callback="doSubmit">
                        <span>{{ $t("GENERAL.BUTTON.CONTINUE") }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import SelectOrganisationForm from './SelectOrganisationForm.vue';

export default {
    name: "SelectOrganisationModal",
    components: { SelectOrganisationForm },
    props: {
        organisation: {
            type: Object,
            default: () => ({ }),
        },
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        doSubmit() {
            this.$refs['select-organisation-form'].doSubmit();
        },
        organisationSelected(){
            this.$emit('organisationSelected')
        }
    },
};
</script>
